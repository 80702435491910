<template>
  <div class="video-mon">
    <div class="left">
      <div class="title">设备列表({{num}}/{{list.length}})</div>
      <div>
        <div @click="playVideo(item)" :style="`background: ${item.online ? 'rgba(255, 255, 255, 0.07)' : ''}`" class="item-env" v-for="(item, index) in list" :key="index">
          <div class="env">
            <div>{{item.unit_name}}</div>
            <div>{{item.unit_member}}</div>
          </div>
          <div :style="`color: ${item.online ? '#1ABEAE' : '#484848'}`" class="point"><span :style="`background-color: ${item.online ? '#1ABEAE' : '#484848'}`"></span>{{item.online ? '在线' : '离线'}}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div id="cmsv6flash"></div>
      <!-- <div @click="playVideo">确认</div>
      <div @click="onTalkback">对讲</div> -->
      <div class="window">
        <span @click="setWindowNum(item)" class="win-num" v-for="item in window" :key="item">{{item}}</span>

        <span class="subtitle">自定义窗口: </span>

        <el-input v-model="windowNumber" type="number" class="win-inp" style="width: 100px; margin-right: 20px;" placeholder="请输入"></el-input>

        <el-button @click="confirm" style="margin-right: 20px" type="primary" size="mini">确认</el-button>
        <el-button @click="initVideo" type="warning" size="mini">初始化</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getMonitorList } from '@/api/monitor'
export default {
  name: 'VideoMonitor',
  data() {
    return {
      session: '',
      list: [],
      num: 0, // 已存在的列表数量
      window: [1, 4, 6, 9, 16],
      windowNumber: 4, // 视频窗口
      windowIndex: 0, // 视频播放索引
      unitMember: [], // 设备列表
    }
  },
  updated() {
    this.$store.commit('SET_BC', false)
    this.$store.commit('SET_COLOR', '#000')
  },
  beforeDestroy() {
    this.$store.commit('SET_BC', true)
    this.$store.commit('SET_COLOR', '')
  },
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      getMonitorList().then((res) => {
        this.session = res.result.session
        this.list = res.result.info
        this.list.forEach(item => {
          if (item.online) this.num++
        })
        this.initPlayerExample()
      })
    },
    initPlayerExample: function () {
      var params = {
        lang: 'zh-cn',
      }
      // 初始化flash
      // eslint-disable-next-line no-undef
      // swfobject.embedSWF('@/player/player.swf', 'cmsv6flash', 400, 400, '11.0.0', null, null, params, null)
      // 初始化视频播放器
      // eslint-disable-next-line no-undef
      ttxVideoAll.init('cmsv6flash', 400, 400, params, 'auto')
      this.initFlash()
    },
    initFlash: function () {
      // eslint-disable-next-line no-undef
      // console.log(typeof swfobject.getObjectById('cmsv6flash').setWindowNum)
      if (
        typeof swfobject === 'undefined' ||
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash') == null ||
        // eslint-disable-next-line no-undef
        typeof swfobject.getObjectById('cmsv6flash').setWindowNum ===
          'undefined'
      ) {
        setTimeout(this.initFlash, 50)
      } else {
        console.log('过劳了00')
        // 设置视频插件的语言('playerType=flash'时生效)
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').setLanguage('cn.xml')
        // 先将全部窗口创建好
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').setWindowNum(36)
        // 再配置当前显示的窗口数目
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').setWindowNum(this.windowNumber)
        // 设置视频插件的服务器ip和登录服务器端口
        // eslint-disable-next-line no-undef
        swfobject
          .getObjectById('cmsv6flash')
          .setServerInfo('116.62.10.18', '6605')
      }
    },

    initVideo: function() {
      // swfobject.getObjectById('cmsv6flash').stopVideo(0)

      for (let i = 0; i < this.windowIndex; i++) {
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').stopVideo(i)
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').reSetVideo(i)
      }
      this.unitMember = []
      this.setWindowNum(4)
      this.windowIndex = 0
    },

    onTalkback: function () {
      // console.log(this.session, '：：：：下')
      // // eslint-disable-next-line no-undef
      // swfobject.getObjectById('cmsv6flash').setTalkParam(1)
      // // eslint-disable-next-line no-undef
      // const a = swfobject
      //   .getObjectById('cmsv6flash')
      //   .startTalkback(this.session, '0020001', 0, '116.62.10.18', '6605')
      // console.log(a, '这是：：：：：：：：：：')
    },

    confirm: function() {
      if (Number(this.windowNumber) === 8) {
        this.$toast('暂不支持 8 窗口')
        return false
      } else if (Number(this.windowNumber) > 36) {
        this.$toast('超出最大窗口')
        return false
      }
      if (this.windowNumber) {
        this.initFlash()
      }
    },

    setWindowNum: function(number) {
      // eslint-disable-next-line no-undef
      // swfobject.getObjectById('cmsv6flash').reSetVideo(number)
      if (this.windowNumber === number) return false
      this.windowNumber = number
      this.initFlash()
    },

    playVideo: function (item) {
      if (!item.online) return false

      if (this.unitMember.indexOf(item.unit_member) > -1) return false

      // eslint-disable-next-line no-undef
      swfobject.getObjectById('cmsv6flash').setVideoInfo(this.windowIndex, item.unit_name)

      // eslint-disable-next-line no-undef
      swfobject
        .getObjectById('cmsv6flash')
        .startVideo(this.windowIndex, this.session, item.unit_member, 0, 0, true)
      this.windowIndex++
      this.unitMember.push(item.unit_member)
    },
  },
}
</script>

<style lang="stylus" scoped>
.video-mon {
  display: flex;
  align-content: center;
  justify-content: space-between;
  height 100%;

  .right {
    width: 85%;
    background-color #000000;
    #cmsv6flash{
      height 93% !important;
      width 100% !important;
    }
    .window{
      width 100%;
      height 7%;
      background-color #171717;
      display flex;
      align-items center;
      .win-num {
        width 28px;
        height 28px;
        display inline-block;
        background-color #1ABEAE;
        border-radius 3px;
        color: #fff;
        margin-right 11px;
        line-height 28px;
        text-align center;
        font-size: 14px !important;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        cursor pointer
      }
      .subtitle {
        margin-left 15px;
        font-size: 14px !important;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #515A6E;
        display inline-block;
        margin-right 5px;
      }
    }
  }

  .left {
    width: 15%;
    height: 100%;
    background-color: #171717;
    .title {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.61);
      margin 10px 0;
      margin-left 15px;
      height 42px;
      line-height 42px;
      border-bottom 1px solid #000000;
    }
    .item-env{
      height 54px;
      width 90%;
      padding 0 5%;
      display flex;
      align-items center;
      justify-content space-between;
      color #fff;
      border-bottom 1px solid #000000;
      cursor pointer
      .env {
        div {
          &:nth-of-type(1) {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
          }
          &:nth-of-type(2) {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #515A6E;
          }
        }

      }
      .point {
        width 20%;
        display flex;
        align-items center;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        // color: #1ABEAE;
        span {
          width: 5px;
          height: 5px;
          // background: #1ABEAE;
          border-radius: 50%;
          display inline-block;
          margin-right 5px;
        }
      }
    }
  }
}
</style>
